import React from 'react';
import {Link} from 'react-router-dom';

class Home extends React.Component {
  constructor(props){
    super(props);
    
  }

	componentDidMount () {
    window.OpenNav()
	}
	
  render() {
    let user = this.props.User//JSON.parse(window.localStorage.getItem("User"));
    let userOrg = user.User.UserOrganizationType;
    return (
      
      <div className="row">
        <br/><br/>
        <div className="d-flex justify-content-center  my-5 ml-5 mr-4">
          <div data-toggle="popover" data-trigger="click" className="p-0 pr-1">            
                <Link key="" to="/CheckIndividual">
                  <div className="card mb-3 border-info" style={{width: '450px'}}>
                      <div className="card-header" style={{backgroundColor : '#00A1D2', color : 'white'}}>
                          <i className= {"fa fa-user fa-4x"} aria-hidden="true"></i>
                          <h3 className="card-title" style={{paddingTop:'15px', float:'right'}}>Check Individual Passenger</h3>
                      </div>
                      <div className="card-body" style={{ padding: '0.50rem' }}>
                          <div className="card-text text-right text-secondary">
                              Click here
                          </div>
                      </div>
                  </div>
                  </Link>              
          </div>

        </div>
        <div className="d-flex justify-content-center  my-5 ml-4 mr-5">
          
          <div data-toggle="popover" data-trigger="click" className="p-0 pl-1">            
            <Link key="" to="/CheckFlight">
            <div className="card mb-3 border-info" style={{width: '450px'}}>
                <div className="card-header" style={{backgroundColor : '#00A1D2', color : 'white'}}>
                    <i className= {"fa fa-plane fa-4x"} aria-hidden="true"></i>
                    <h3 className="card-title" style={{paddingTop:'15px', float:'right'}}>Check Flight</h3>
                </div>
                <div className="card-body" style={{ padding: '0.50rem' }}>
                    <div className="card-text text-right text-secondary">
                        Click here
                    </div>
                </div>
            </div>
            </Link>
          </div>
        </div>

        {/* <div className="d-flex justify-content-center  my-0 ml-5 mr-4">
          
          <div style = {(userOrg != "ADMIN")? {display: 'none'} :{}} data-toggle="popover" data-trigger="click" className="p-0 ">            
            <Link key="" to="/CheckAirline">
            <div className="card mb-3 border-info" style={{width: '450px'}}>
                <div className="card-header" style={{backgroundColor : '#00A1D2', color : 'white'}}>
                    <i className= {"fa fa-plane fa-4x"} aria-hidden="true"></i>
                    <h3 className="card-title" style={{paddingTop:'15px', float:'right'}}>Check Airline</h3>
                </div>
                <div className="card-body" style={{ padding: '0.50rem' }}>
                    <div className="card-text text-right text-secondary">
                        Click here
                    </div>
                </div>
            </div>
            </Link>
          </div>
        </div> */}
      </div>
		);
	}
}

export default Home;
