import React from 'react';
import {appCred} from '../AppConfig.js';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';

class CheckAirline extends React.Component {
  constructor(){
    super();

    let user = JSON.parse(window.localStorage.getItem("User"));
    
    this.state = {
      b64Report : '',
      FlightDate :null,
      isLoading : false,
      NoRecordFound : false,
      FlightCarrier :'',
      FlightData : [],
      FlightDataSearch : [],
      txtSearch:'',
      UserOrgType : user.User.UserOrganizationType,
      UserOrgId : user.User.UserOrganizationId,
      FlightNo : '',// (user.User.UserOrganizationType == "AIRLINE") ? user.User.UserOrganizationId : '' ,
      FlightCarrierList : user.User.lstOrganizations
    }
  }

  handleSearch = (event)=>{
    //console.log(event.target.value.toLowerCase());
    if (event.target.value.toLowerCase() != ''){
      this.refs.ddiv.style.display = 'none'
    }else{
      this.refs.ddiv.style.display = 'block'
    }
    let temp  = this.state.FlightData.filter(function(item) {
        return (item.PassengerName.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.PassengerPassportNo.toLowerCase().includes(event.target.value.toLowerCase()) ||
                (item.Result||'').toLowerCase().includes(event.target.value.toLowerCase()) ||
                (item._Result||'').toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.FlightBookingRefNo.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.Gender.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.UnitNumber.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.SpecimenId.toLowerCase().includes(event.target.value.toLowerCase())
        )
    })
    this.setState({
      FlightDataSearch : temp
    })
}  

handleResultStatus = (property, key)=>{
  
  this.refs.SearchBox.value = '';
  let temp  = this.state.FlightData.filter(function(item) {
      if(property == 'All'){
        return true;
      }  
      else if(property == 'Result'){
        console.log(item.Result);
        console.log(property);
        console.log(key);
        return ((item.Result||'').toLowerCase() == (key.toLowerCase()))
      }
      else if(property == 'Status'){
        return ((item.Status||'').toLowerCase() == (key.toLowerCase())) 
      }
  })
  this.setState({
    FlightDataSearch : temp
  })
}  

  ClearInputs = () =>{
    if(this.state.UserOrgType != "AIRLINE"){
      this.refs.FlightCarrier.value = 'Select Carrier';
    }   

    this.setState({
      FlightDate : '',
      FlightData : [],
      FlightDataSearch : [],
      //FlightNo : '',
      //FlightCarrier: ''
    })
  }

  

  handleReport = (key)=>{
    
    console.log('fetchReport called ' + key)

    if(key == "PDF"){
      console.log('pdf');
    let reqData = '';
    if (this.state.UserOrgType == "AIRLINE"){
      reqData = { "key": "FLIGHT", "outExt": key, "qFlightCareer" : this.state.UserOrgId, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY'), "qFlightNumber" : this.state.FlightNo }
    }else{
      reqData = { "key": "FLIGHT", "outExt": key, "qFlightCareer" : this.state.FlightCarrier }
    }
    
    window.APIRequestAuth(appCred,'AirlineCovidManager.ExtractReportB64',reqData).then((apiRes)=>{
      console.log(apiRes);
      
      if(apiRes.ResponseCode == 200){
        this.setState({
          b64Report : apiRes.ResponseResult
        })

        if(key=="PDF"){

          // window.OpenPDFViewer(apiRes.ResponseResult);
          // let pdfWindow = window.open("")
          // pdfWindow.document.write(
          //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          //     encodeURI(apiRes.ResponseResult) + "'></iframe>"
          // )
          
          //window.localStorage.setItem("fetchedPDF", apiRes.ResponseResult);
          //window.open('/PDFView');

          var byteCharacters = atob(apiRes.ResponseResult);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var file = new Blob([byteArray], { type: 'application/pdf;base64' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        else{
        }
      }
    }).catch((error) => {
    console.log(error)
  })
    }
    else{
      console.log('excel');
      let translationObj = {
        "SpecimenId" : "Specimen Id",
        "RequestNumber" : "Request Number",
        "UnitNumber" : "Patient Unit Number",
        "PassengerName" : "Passenger Name",
        "PassengerPassportNo" : "Passport Number",
        "FlightCareer" : "Flight Carrier",
        "DisplayFlightDate" : "Flight Date",
        "FlightNumber" : "Flight Number",
        "FlightBookingRefNo" : "Booking Reference",
        "DisplayCollectionDttm" : "Specimen Collection Date",
        "DisplaySignoutDttm" : "Specimen Resulted Date",
        "Status" : "Report Status",
        "Description" : "Ordered Test",
        "Result" : "Result",
        "_Result" : "Result (Simplified)",
        "PatientAge" : "Passenger Age",
        "Gender" : "Passenger Gender",
        "Location" : "Test Perform City"
      };

      let translatedArr = [];

      for(let i=0; i< this.state.FlightData.length; i++ ){
        let obj = {};
        //for(let key in Object.keys(translationObj)){
          Object.keys(translationObj).forEach((key,index)=>{
            obj[translationObj[key]] = this.state.FlightData[i][key];
          })
        translatedArr.push(obj);
      }
      
      console.log(translatedArr);

      window.OpenEXCELViewer(translatedArr);
    }

  }


  fetchFlightDetails = ()=>{
    this.setState({
      isLoading : true
    })
    console.log('fetchFlightDetails called')
    //console.log(this.state.FlightDate);
    let reqData = '';
    if (this.state.UserOrgType == "AIRLINE"){
      reqData = { "key": "ALL", "qFlightCareer" : this.state.UserOrgId, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY'), "qFlightNumber" : this.state.FlightNo }
    }else{
      reqData = { "key": "ALL", "qFlightCareer" : this.state.FlightCarrier  }
    }
    
    console.log(this.reqData);
    window.APIRequestAuth(appCred,'AirlineCovidManager.GetFlightData',reqData).then((apiRes)=>{
      console.log(apiRes);
      
      let _searchablelist = JSON.parse(JSON.stringify(apiRes.ResponseResult));
      if(apiRes.ResponseCode == 200){
        this.setState({
          isLoading : false,
          FlightData : apiRes.ResponseResult,
          FlightDataSearch : _searchablelist
        })
      }
      else if(apiRes.ResponseCode == 204){
        this.setState({
          isLoading : false,
          NoRecordFound : true,
          FlightData : [],
          FlightDataSearch : []
        })
      }
    }).catch((error) => {
    console.log(error)
  })

  }

  handleFlightNo = (event) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      FlightNo: event.target.value
    });
  }

  handleFlightCarrier = (event) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      FlightCarrier: event.target.value
    });
  }



  setFlightDate = (date) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      FlightDate: date
    });
  }

  
  getBorderColor = (obj)=>{
    if(obj.Status == "Completed"){
      if(obj.Result == "Positive"){      
        return "red";
      } else if(obj.Result == "Negative"){
        return "green";
      } 
    }else{
      return "darkorange";
    }
  }
	componentDidMount () {
    window.CloseNav();
  }
	
  render() {
    
    return (
      <div className="row m-4" >
        <br/> <br/>
        <span style={{width:'100%', fontSize:'25px', fontWeight:'500', paddingBottom:'10px'}}> Check Airline </span>
        
          <div className="row" style={{width:'100%', marginLeft:'5px'}}>
            { 
              (this.state.UserOrgType != "AIRLINE")?
              <div className="col-3" style={{paddingLeft:'0px', marginLeft:'-5px'}}>
            <select placeholder="Flight Carrier" 
                        ref = "FlightCarrier"
                        className="form-control"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handleFlightCarrier(event)} 
                        style={{marginBottom:'5px'}}>
                          
                         <option>Select Carrier</option>
                         {
                           (this.state.FlightCarrierList)?
                            this.state.FlightCarrierList.map((obj, i)=>
                          <option value={obj.id} key={i}>{obj.text}</option>
                         )
                         :
                         <option>Select Carrier</option>
}
                        </select>
          </div>
          
        :
        <div></div>
            }
          <div className="col" style={{marginTop:'5px'}}>
          <input  onClick={this.fetchFlightDetails} 
                  type="button" 
                  className="btn btn-info SearchTblbuttonpos" 
                  name="" 
                  value="Search" 
                  style={{marginLeft:'10px'}} />
          <input  onClick={this.ClearInputs} 
                  type="button" 
                  className="btn btn-warning SearchTblbuttonpos" 
                  name="" 
                  value="Clear" 
                  style={{marginLeft:'10px'}} />
                  <Link to="/home">
          <input type="button" 
                  className="btn btn-light SearchTblbuttonpos" 
                  name="" 
                  value="Back" 
                  style={{marginLeft:'10px'}} />
        
                  </Link>
          
        
        </div>
          

          </div>


           
          {                        
                        this.state.isLoading ?
                        <div className='loader' style={{textAlign:'center', color:'#3a99c8',width:'100%'}}><i className='fa fa-spinner fa-spin fa-3x fa-fw'></i></div>
                        :
                        (this.state.FlightData.length > 0)?
                      <div className="row" style={{marginTop:'15px', width:'100%', marginLeft:'-5px'}}>
                        
                        <div className="col"> 
                         <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{marginLeft:'-15px', marginBottom:'15px', width:'100%', borderColor:'blue'}}>
                          <div className="col-5 nav nav-tabs"  role="tablist" style={{borderBottom:'0px'}}>
                            <a onClick={()=>this.handleResultStatus('All')} className="nav-item nav-link active"  data-toggle="tab" href="#nav-CW" role="tab" aria-controls="nav-CW" aria-selected="true">All</a>
                            <a onClick={()=>this.handleResultStatus('Result','Positive')} className="nav-item nav-link" data-toggle="tab" href="#nav-Completed" role="tab" aria-controls="nav-Completed" aria-selected="false">Postive</a>
                            <a onClick={()=>this.handleResultStatus('Result','Negative')} className="nav-item nav-link" data-toggle="tab" href="#nav-Cancelled" role="tab" aria-controls="nav-Cancelled" aria-selected="false">Negative</a>
                            <a onClick={()=>this.handleResultStatus('Status','Pending')} className="nav-item nav-link" data-toggle="tab" href="#nav-Cancelled" role="tab" aria-controls="nav-Cancelled" aria-selected="false">Pending</a>
                          
                          </div>
                          
          <div className="col-2 text-right">  <p className="text-info mb-0">{(this.state.FlightDataSearch.length>0)? this.state.FlightDataSearch.length : <span>No </span> } Records Found.</p> </div>
          <div className="col-1 ">
            <div ref="ddiv">
            {<a title="Download Excel" style={{cursor:'pointer'}} onClick={()=>this.handleReport('EXCEL')}> <i className="fa fa-file-excel-o fa-2x text-success"></i></a>} 
          {<a title="Download PDF" style={{cursor:'pointer'}} onClick={()=>this.handleReport('PDF')}> <i className="fa fa-file-pdf-o fa-2x text-danger"></i></a>}
          
          </div>
          </div>
                          <div className="col" style={{ paddingLeft:'0px', width:'100%', marginLeft:'-5px'}}>
                            <input  placeholder="Search" 
                            type="text" 
                            ref="SearchBox"
                            className="form-control"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={(event) => this.handleSearch(event)} 
                            style={{}}
                            />
                          </div>
                          
                        </nav>
                        </div>
                        
          
                      </div>
                      :
                      this.state.NoRecordFound ?
                          <div><br/><p className="text-info pl-2">No Record Found</p></div>
                          :
                        <div></div>
                    }

                    <div className="row pr-3 pl-3 pt-0" style={ (this.state.FlightDataSearch.length > 0)?  {overflowY: 'scroll', width:'1300px', float: 'left', height:'300px'} : {}}>
                      {
                        (this.state.FlightDataSearch)?                        
                        this.state.FlightDataSearch.map((obj, i)=>
                          <div className="row" key={i} style={{maxHeight:'108px', marginTop:'0px', marginBottom:'15px', marginLeft:'-5px',width:'100%'}}>
                          <div className="card " style={{ color:'black',width:'1250px',backgroundColor:'whitesmoke', borderLeftWidth:'5px', borderColor:this.getBorderColor(obj)}}>
                            
                            <div className="card-body" style={{padding:'10px'}}>                             
                            
                              <div className="row">
                              <div className="col-content">
                              <p  style={{ fontSize: '15px', paddingLeft:'35px', marginBottom:'0px', color:'lightgray'}}> #{i + 1 }</p>
                            <span style={{float: 'left', fontSize: '40px', paddingLeft:'25px', paddingRight:'10px', color:'orange'}}>
                      
                              <i className="fa fa-flask" aria-hidden="true"></i>
                              </span>
                              </div>
                                <div className="col-3 pl-2 pr-0">
                                  <p style={{marginBottom:'0px',fontWeight:'500'}}>{obj.PassengerName}</p>
                                  <p style={{marginBottom:'10px',fontSize:'11px',fontWeight:'500'}}>{obj.Gender} | {obj.PatientAge} Y</p>
                                  
                                  
                                  <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Booking Reference / PNR</p>
                                  <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.FlightBookingRefNo}</p>
                                </div>
                                <div className="col-2 px-0 mr-4" style={{maxWidth:'150px', borderRight:'1px solid lightgray'}}>
                                <p style={{marginBottom:'25px',fontWeight:'500'}}>{obj.PassengerPassportNo}</p>
                                <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Flight # / Date</p>
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{/*obj.FlightCareer */} {obj.FlightNumber} / {obj.DisplayFlightDate}</p>
                                
                                </div>
                                <div className="col-3 px-2">
                                <p style={{marginBottom:'0px',fontSize:'11px',color:'gray'}}>Test</p>
                                <p style={{marginBottom:'5px',fontSize:'large',fontWeight:'600'}}>{obj.Description}</p>
                                
                                
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.UnitNumber} / {obj.SpecimenId}</p>
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.Location}</p>
                                </div>
                                
                                <div className="col px-2">
                                <p  style={{marginBottom:'0px',fontSize:'11px',color:'gray'}}>Result </p>
                                <p style={{fontSize:'large',fontWeight:'600'}}>{obj._Result}</p>


                                {
                                    (obj.Status == "Completed")?
                                      
                                  (obj.Result == "Negative")?  
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'green'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                :
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'red'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                
                                :
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'darkorange'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Status}</p>
                                }
                                </div>
                                
                                <div className="col px-2">
                                <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Specimen Collection</p>
                                
                                {
                                  (obj.DisplayCollectionDttm)?
                                  <p style={{marginBottom:'15px',fontSize:'small'}}>{obj.DisplayCollectionDttm}</p>
                                :
                                  <p style={{marginBottom:'15px',fontSize:'small'}}>N/A</p>
                                }
                                <p  style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Result Released </p>
                                {
                                  (obj.DisplaySignoutDttm)?
                                <p  style={{marginBottom:'0px',fontSize:'small'}}>{obj.DisplaySignoutDttm}</p>
                                :
                                <p  style={{marginBottom:'0px',fontSize:'small'}}>N/A</p>
                                }
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        )
                        :
                        <div></div>  
                      }
                      </div>
      </div>
		);
	}
}

export default CheckAirline;
