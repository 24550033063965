import React from 'react';

class NotFound extends React.Component {
	componentDidMount () {

	}
	
  render() {
    return (
		<div className="">
        Not Found
    </div>
		);
	}
}

export default NotFound;
