import React from 'react';
class ModalContent extends React.Component{

  render(){
    return (
      this.props.Content()
    );
  }
}

export default ModalContent;