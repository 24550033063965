import React from 'react';

class TestModal extends React.Component {
	componentDidMount () {

	}
	
  render() {
    return (
		
      <div>Hello I am test</div>

		);
	}
}

export default TestModal;
