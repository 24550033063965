import React from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends React.Component {
  constructor(){
    super();

    this.state ={
      userName : '',
      userOrg :'',
      UserId :'',
      userRole:'',
      srcOrgLogoUrl : '',
      lstOrganizations :[]
    };
  }

  fetchUserDetails = () =>{
    console.log('hereUser');
    let user = window.mylsataku.get('cache2'); //JSON.parse(window.localStorage.getItem("User"));
    //let refresh_token = user.User.UserName;
    this.setState ({
      userName : user.User.UserName,
      userOrg :user.User.UserOrganizationId,
      userRole:user.User.UserOrganizationType,
      UserId: user.User.UserID,
      srcOrgLogoUrl :user.User.OrgLogoUrl,
      lstOrganizations : user.User.lstOrganizations
    });

//     console.log(this.state.userOrg + "  this.state");
//     let temp  = this.state.lstOrganizations.filter(function(item) {
//       return ((item.id||'').toLowerCase() == (this.state.userOrg.toLowerCase()))
//     })
// console.log(temp);
//     this.setState({
//       userOrg : temp.map((obj)=>  obj.text )
//     })

  }
  rLogOut = () =>{
    window.mylsataku.removeAll();
    //localStorage.clear();
    window.location = '/';
  }

	componentDidMount () {
    this.fetchUserDetails();
	}
	
  render() {
    return (
      <div id="mySidenav" className="sidenav slide-in-blurred-left" style={{height:'100vh',position: 'sticky', marginTop: '50px', marginRight: '10px', float: 'left'}}>
      <div style={{textAlign: 'center', paddingTop: '30px', paddingBottom: '10px', backgroundColor: '#eaeaea'}}>
{        /*
<img src={this.state.srcOrgLogoUrl} style={{textAlign: 'center', display:'block', margin:'auto', maxHeight:'60px'}} ></img>

<img className="rounded-circle" src={this.props.User.UserPictureB64} alt="Generic placeholder image" width={90} height={90} />
        
*/}
<span ><i className="fa fa-user fa-4x text-secondary" aria-hidden="true"></i></span>
        <br/>
        <h5 style={{paddingTop: '10px'}}>  {this.state.userName} </h5>
        
        <p>{this.state.userRole}</p>
        {/* <p>{this.state.UserId} </p> */}
      </div>
      <Link to="/home"><i className="fa fa-home" style={{padding: '3px', marginRight: '10px'}} aria-hidden="true" />Home</Link>
      <Link to="/CheckIndividual"><i className="fa fa-user" style={{padding: '3px', marginRight: '10px'}} aria-hidden="true" />Check Individual</Link>
      <Link to="/CheckFlight"><i className="fa fa-plane" style={{padding: '3px', marginRight: '10px'}} aria-hidden="true" />Check Flight</Link>
      <Link to="/ChangePassword" style = {(this.state.userOrg == "AKU")? {display: 'none'} :{}}><i className="fa fa-cog" style={{padding: '3px', marginRight: '10px'}} aria-hidden="true" />Change Password</Link>
      {/* <Link to="/CheckAirline" style = {(this.state.userRole != "ADMIN")? {display: 'none'} :{}}><i className="fa fa-plane" style={{padding: '3px', marginRight: '10px'}} aria-hidden="true" />Check Airline </Link>
       */}


      {/*
      

      this.props.User.menus.map((obj, i) => 
        <div key={i}> 
            <Link to="/home"><i style={{padding: '3px', marginRight : '10px'}} className={obj.MenuIcon} aria-hidden="true"></i>{obj.MenuDescription} 
            <span>
            {(obj.lstChildOpts.length > 0 ? 
                <i className="fa fa-caret-right" aria-hidden="true" style={{float: 'right',padding: '3px'}}></i>
                : (<i/>) 
            )}
            </span>
            </Link>
            <div className="sidenavChild" key={i}>
                {obj.lstChildOpts.map((ch, j)=>
                  <div key={j}>
                    <Link to={"/"+ch.OptionLabel.substring(3)}>
                      <i style={{padding: '3px', marginRight : '10px'}} className={ch.MenuIcon} aria-hidden="true"></i>
                      {ch.MenuDescription}
                    </Link>
                  </div>
                )}
            </div>
        </div>
                )*/}
      <a href="#" onClick={this.rLogOut}><i className="fa fa-power-off" style={{padding: '3px', marginRight: '10px'}} aria-hidden="true" />Logout</a>
    </div>
		);
	}
}

export default Sidebar;
