import React from 'react';

class ModalFooter extends React.Component{

  render(){
    return (
      <div className="customModalFooter">
        {this.props.Footer()}
      </div>
    );
  }
}

export default ModalFooter;
