import React from 'react';
import {appCred} from '../AppConfig.js';

class ChangePassword extends React.Component {
	constructor(){
		super();
		this.state = {
		  username : '',
		  oldpassword : '',
		  newpassword : '',
		  verifypassword : '',
		  isLoading : false,
		  customAlert : {
			isDisplay : false,
			AlertText : '',
			AlertType : ''
		  }
		}
		
	  }

	  rChangePassword = () =>{
		//console.log('here')

		window.ValidateChangePassword();
		window.ChangePassword(this,appCred);
	  }


	  KeyPressHook = (event) =>{
		this.setState({
		  customAlert : {
			isDisplay : false,
			AlertText : '',
			AlertType : ''
		  }
		})
		if (event.which === 13) {
		  event.preventDefault();
		  if (this.state.username) {
			  if (this.state.password) {
				  this.rChangePassword();
			  }
			  else {
				  this.passwordInput.focus();
			  }
		  }
		  else {
			  //this.usernameInput.focus();
		  }
	  }
	  }
	
	  KeyUpHook = (event) =>{
		if (event.getModifierState && event.getModifierState('CapsLock')) {
			this.setState({
			  customAlert : {
				isDisplay : true,
				AlertText : 'Caps lock is ON.',
				AlertType : 'alert alert-warning'
			  }
			})
		} else {
			this.setState({
			  customAlert : {
				isDisplay : false,
				AlertText : '',
				AlertType : ''
			  }
			})
		}
	  }
	
	  handleUserInput (e) {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({[name]: value});

		
		window.ValidateChangePassword();
	  }
	componentDidMount () {
		document.addEventListener("keyup", this.KeyUpHook);
		document.addEventListener("keypress", this.KeyPressHook); 
	}
	
  render() {
    return (
		<div className="row m-4">
			<br/> <br/>
			<span style={{width:'100%', fontSize:'25px', fontWeight:'500',paddingLeft:'15px', paddingBottom:'10px'}}> Change Password </span>
        

		
		<div className = "p-2 w-25">
			
      <form id="frmChangePassword" className="form-signin">
	                  {/*<div className="form-label-group">
                        <input value={this.state.username} 
                              onChange={(event) => this.handleUserInput(event)} 
                              type="text" 
                              id="username" 
                              name="username" 
                              className="form-control" 
                              placeholder="Username" required 
                              ref={(input) => { this.usernameInput = input; }}
                              autoFocus
                              />
                        <label htmlFor="username">Username</label>
	</div>*/}
                      <div className="form-label-group" style={{marginBottom:'5px'}}>
                        <input value={this.state.oldpassword} 
                               onChange={(event) => this.handleUserInput(event)} 
                               type="password" 
                               name="oldpassword" 
                               id="OldPassword" 
                               className="form-control" 
                               placeholder="Current Password" 
                               required 
                               ref={(input) => { this.passwordInput = input; }}
                               />
                      </div>
					  <div className="form-label-group" style={{marginBottom:'5px'}}>
                        <input value={this.state.newpassword} 
                               onChange={(event) => this.handleUserInput(event)} 
                               type="password" 
                               name="newpassword" 
                               id="NewPassword" 
                               className="form-control" 
                               placeholder="New Password" 
                               required 
                               ref={(input) => { this.passwordInput = input; }}
                               />
                      </div>
					  <div className="form-label-group" style={{marginBottom:'5px'}}>
                        <input value={this.state.verifypassword} 
                               onChange={(event) => this.handleUserInput(event)} 
                               type="password" 
                               name="verifypassword" 
                               id="ConfirmPassword" 
                               className="form-control" 
                               placeholder="Confirm Password" 
                               required 
                               ref={(input) => { this.passwordInput = input; }}
                               />
                      </div>

						<div className="text-danger p-2" id="msgCP"> </div>

                      <div style={{textAlign: 'center'}}>
                        <button id="btnChangePassword" 
                                className="btn btn-info btn-width60 " 
                                type="button" 
                                onClick={this.rChangePassword}
                                disabled={this.state.isLoading}
                                >
                          Update 
                          {(this.state.isLoading)?<i className="fa fa-spinner fa-spin fa-1x fa-fw" />:<i/>}
                        </button>
                      </div>
                      <input type="submit" style={{display: 'none'}} />
                    </form>
             
		</div>       
    </div>
		);
	}
}

export default ChangePassword;
