import React from 'react';

class Footer extends React.Component {
	componentDidMount () {

	}
	
  render() {
    return (
		
      <footer style={{zIndex: 1, position: 'fixed', bottom: 0, width: '100%', height: '30px', backgroundImage: 'linear-gradient(to right, #a3c6d4, #4792c7)'}}>
        <div style={{float: 'right', marginTop: '3px', marginRight: '10px'}}>
          {/* <span style={{fontSize: 'small', color: 'white', fontWeight: 600}}>
            <i className="fa fa-desktop" aria-hidden="true" style={{paddingRight: '6px'}} />
            {this.props.ServerName}
          </span> */}
        </div>
      </footer>

		);
	}
}

export default Footer;
