import React from 'react';

class Header extends React.Component {
  
  rToggleNav = ()=>{
    window.ToggleNav()
  }
  
  componentDidMount () {

	}
  
  render() {
    return (
      <header style={{zIndex: 1, position: 'fixed', width: '100%', paddingTop: '5px', height: '50px', backgroundImage: 'linear-gradient(to right, #4cadd2, #1a75b5)'}}>
      <div>
        <span title="" style={{fontFamily: '"Raleway", sans-serif', padding: '20px', fontSize: '26px', textAlign: 'left', fontWeight: 600, color: 'white', cursor: 'pointer'}} onClick={this.rToggleNav}>
          Travel Check  &nbsp; ☰
        </span>
      </div>
    </header>      
		);
	}
}

export default Header;
