import React from 'react';
import {appCred} from '../AppConfig.js';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import TestModal from './TestModal.js';

class CheckFlight extends React.Component {
  constructor(){
    super();

    let user = window.mylsataku.get('cache2'); //JSON.parse(window.localStorage.getItem("User"));
    
    this.state = {
      b64Report : '',
      FlightDate :null,
      isLoading : false,
      NoRecordFound : false,
      FlightCarrier :'',
      FlightData : [],
      FlightDataSearch : [],
      txtSearch:'',
      UserOrgType : user.User.UserOrganizationType,
      UserOrgId : user.User.UserOrganizationId,
      FlightNo : '',// (user.User.UserOrganizationType == "AIRLINE") ? user.User.UserOrganizationId : '' ,
      FlightCarrierList : user.User.lstOrganizations
    }
  }

  handleSearch = (event)=>{
    //console.log(event.target.value.toLowerCase());
    if (event.target.value.toLowerCase() != ''){
      this.refs.ddiv.style.display = 'none'
    }else{
      this.refs.ddiv.style.display = 'block'
    }
    let temp  = this.state.FlightData.filter(function(item) {
        return (item.PassengerName.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.PassengerPassportNo.toLowerCase().includes(event.target.value.toLowerCase()) ||
                (item.Result||'').toLowerCase().includes(event.target.value.toLowerCase()) ||
                (item._Result||'').toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.FlightBookingRefNo.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.Gender.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.UnitNumber.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.SpecimenId.toLowerCase().includes(event.target.value.toLowerCase())
        )
    })
    this.setState({
      FlightDataSearch : temp
    })
}  

handleResultStatus = (property, key)=>{
  
  this.refs.SearchBox.value = '';
  let temp  = this.state.FlightData.filter(function(item) {
      if(property == 'All'){
        return true;
      }  
      else if(property == 'Result'){
        //console.log(item.Result);
        //console.log(property);
        //console.log(key);
        return ((item.Result||'').toLowerCase() == (key.toLowerCase()))
      }
      else if(property == 'Status'){
        return ((item.Status||'').toLowerCase() == (key.toLowerCase())) 
      }
  })
  this.setState({
    FlightDataSearch : temp
  })
}  

  ClearInputs = () =>{
    this.refs.FlightDate.value = '';
    this.refs.FlightNo.value = '';
    if(this.state.UserOrgType != "AIRLINE"){
      this.refs.FlightCarrier.value = '';
    }
    

    this.setState({
      FlightDate : '',
      FlightData : [],
      FlightDataSearch : [],
      FlightNo:'',
      FlightCarrier:''
      //FlightNo : '',
      //FlightCarrier: ''
    })
  }

  

  handleReport = (key)=>{
    
    console.log('fetchReport called ' + key)

    this.refs.divLoader.style.display = 'block'   
    if(key == "PDF"){this.refs.PDF.style.display = 'none'}else{this.refs.EXCEL.style.display = 'none'}
    
    if(key == "PDF"){
      console.log('pdf');
    
    
    let reqData = '';
    if (this.state.UserOrgType == "AIRLINE"){
      if (this.state.FlightDate){
        console.log("IF date " + this.state.FlightDate)
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.UserOrgId, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY'), "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightNo)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.UserOrgId, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY')}
        }
      }else{
        console.log("else date " + this.state.FlightDate)        
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.UserOrgId, "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightDate)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.UserOrgId }
        }        
      }
    }else{

      if (this.state.FlightDate){
        console.log("IF date " + this.state.FlightDate)
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.FlightCarrier, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY'), "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightNo)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.FlightCarrier, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY') }
        }
      }else{
        console.log("else date " + this.state.FlightDate)        
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.FlightCarrier, "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightDate)
          reqData = { "key": "ALL", "outExt": key, "qFlightCareer" : this.state.FlightCarrier}
        }
        
      }
    }

    window.APIRequestAuth(appCred,'AirlineCovidManager.ExtractReportB64',reqData).then((apiRes)=>{
      //console.log(apiRes);
      
      if(apiRes.ResponseCode == 200){
        this.setState({
          b64Report : apiRes.ResponseResult
        })

        if(key=="PDF"){

          window.OpenPDFViewer(apiRes.ResponseResult);
          this.refs.divLoader.style.display = 'none'   
          this.refs.PDF.style.display = 'inline'
    
          // let pdfWindow = window.open("")
          // pdfWindow.document.write(
          //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          //     encodeURI(apiRes.ResponseResult) + "'></iframe>"
          // )
          
          //window.localStorage.setItem("fetchedPDF", apiRes.ResponseResult);
          //window.open('/PDFView');

          //location.href =  fileURL;
        }
        else{
        }
      }
    }).catch((error) => {
    console.log(error)
  })
    }
    else{
      if(this.state.UserOrgId == "EY" || this.state.FlightCarrier == "EY"){
        console.log('excel EY');
        let translationObj = {
          "UnitNumber" : "Case Number",
          "DisplayFlightDate" : "Dep. Date",
          "FlightNumber" : "Flt. No.",
          "FirstName" : "First Name",
          "LastName" : "Last Name",
          "PatientAge" : "Age",
          "Gender" : "Gender",
          "CellNumber" : "Mobile",
          "FlightBookingRefNo" : "Booking Ref. #",
          "TicketNumber" : "Ticket Number",
          "PassengerPassportNo" : "Passport No.",
          "DisplayCollectionDttm" : "Registration Date",
          "Description" : "Lab Name",
          "_Result" : "Result"
        };

        let translatedArr = [];

        for(let i=0; i< this.state.FlightData.length; i++ ){
          let obj = {};
            Object.keys(translationObj).forEach((key,index)=>{
              obj[translationObj[key]] = this.state.FlightData[i][key];
            })
          translatedArr.push(obj);
        }      
        window.OpenEXCELViewer(translatedArr);
        
        this.refs.divLoader.style.display = 'none'   
        this.refs.EXCEL.style.display = 'inline'       
      }
      else{
        console.log('excel');
        let translationObj = {
          "SpecimenId" : "Specimen Id",
          "RequestNumber" : "Request Number",
          "UnitNumber" : "Patient Unit Number",
          "PassengerName" : "Passenger Name",
          "TicketNumber" : "Ticket Number",
          "PassengerPassportNo" : "Passport Number",
          "FlightCareer" : "Flight Carrier",
          "DisplayFlightDate" : "Flight Date",
          "FlightNumber" : "Flight Number",
          "FlightBookingRefNo" : "Booking Reference",
          "FinalDestination" : "Final Destination",
          "DisplayCollectionDttm" : "Specimen Collection Date",
          "DisplaySignoutDttm" : "Specimen Resulted Date",
          "Status" : "Report Status",
          "Description" : "Ordered Test",
          "Result" : "Result",
          "_Result" : "Result (Simplified)",
          "PatientAge" : "Passenger Age",
          "Gender" : "Passenger Gender",
          "Location" : "Test Perform City"
        };

        let translatedArr = [];

        for(let i=0; i< this.state.FlightData.length; i++ ){
          let obj = {};
          //for(let key in Object.keys(translationObj)){
            Object.keys(translationObj).forEach((key,index)=>{
              obj[translationObj[key]] = this.state.FlightData[i][key];
            })
          translatedArr.push(obj);
        }
        
        //console.log(translatedArr);

        window.OpenEXCELViewer(translatedArr);
        
        this.refs.divLoader.style.display = 'none'   
        this.refs.EXCEL.style.display = 'inline'
      
      
      }
    }
  }


  fetchFlightDetails = ()=>{
    this.setState({
      isLoading : true
    })
    console.log('fetchFlightDetails called')
    //console.log(this.state.FlightDate);
    let reqData = '';
    if (this.state.UserOrgType == "AIRLINE"){
      if (this.state.FlightDate){
        console.log("IF date " + this.state.FlightDate)
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "FLIGHT", "qFlightCareer" : this.state.UserOrgId, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY'), "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightNo)
          reqData = { "key": "ALL", "qFlightCareer" : this.state.UserOrgId, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY') }
        }
      }else{
        console.log("else date " + this.state.FlightDate)        
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "ALL", "qFlightCareer" : this.state.UserOrgId, "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightDate)
          reqData = { "key": "ALL", "qFlightCareer" : this.state.UserOrgId}
        }        
      }
    }else{

      if (this.state.FlightDate){
        console.log("IF date " + this.state.FlightDate)
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "FLIGHT", "qFlightCareer" : this.state.FlightCarrier, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY'), "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightNo)
          reqData = { "key": "ALL", "qFlightCareer" : this.state.FlightCarrier, "qFlightDate" : moment(this.state.FlightDate).format('DD/MM/YYYY') }
        }
      }else{
        console.log("else date " + this.state.FlightDate)        
        if(this.state.FlightNo){
          console.log("IF # " + this.state.FlightNo)
          reqData = { "key": "ALL", "qFlightCareer" : this.state.FlightCarrier, "qFlightNumber" : this.state.FlightNo }
        }else{
          console.log("else # " + this.state.FlightDate)
          reqData = { "key": "ALL", "qFlightCareer" : this.state.FlightCarrier}
        }
        
      }
    }
    
    //console.log(reqData);
    
    window.APIRequestAuth(appCred,'AirlineCovidManager.GetFlightData',reqData).then((apiRes)=>{
      //console.log(apiRes);
      
      let _searchablelist = JSON.parse(JSON.stringify(apiRes.ResponseResult));
      if(apiRes.ResponseCode == 200){
        this.setState({
          isLoading : false,
          FlightData : apiRes.ResponseResult,
          FlightDataSearch : _searchablelist
        },()=>{
          window.calcHeight();
        })
      }
      else if(apiRes.ResponseCode == 204){
        this.setState({
          isLoading : false,
          NoRecordFound : true,
          FlightData : [],
          FlightDataSearch : []
        })
      }
    }).catch((error) => {
    console.log(error)
  })

  }

  handleFlightNo = (event) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      FlightNo: event.target.value
    });
  }

  handleFlightCarrier = (event) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      FlightCarrier: event.target.value
    });
  }

  OpenConsentForm = (base64img) => {
    console.log('modal here' + base64img);
    window._ModalContext.SetModalContext("Consent Form", 
    ()=>{return<div style={{overflowY : 'auto', height:'70vh'}}><img src={base64img} style={{height: '1000px'}}  /></div> },
    ()=>{return <div></div>});
    window.OpenModal('large');
  }


  setFlightDate = (date) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      FlightDate: date
    });
  }

  
  getBorderColor = (obj)=>{
    if(obj.Status == "Completed"){
      if(obj.Result == "Positive"){      
        return "red";
      } else if(obj.Result == "Negative"){
        return "green";
      } 
    }else{
      return "darkorange";
    }
  }
	componentDidMount () {
    window.CloseNav();
    // if (this.state.UserOrgType == "AIRLINE"){
    //   this.fetchFlightDetails();
    // }
  }
	
  render() {
    
    return (
      <div className="row m-4" >
        <br/> <br/>
        <span style={{width:'100%', fontSize:'25px', fontWeight:'500', paddingBottom:'10px'}}> Check Flight </span>
        
          <div className="row" style={{width:'100%', marginLeft:'5px'}}>
            { 
              (this.state.UserOrgType != "AIRLINE")?
              <div className="col" style={{paddingLeft:'0px', paddingRight:'40px', marginLeft:'-5px'}}>
            <select placeholder="Flight Carrier" 
            defaultValue = {'DEFAULT'}
                        ref = "FlightCarrier"
                        className="form-control"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handleFlightCarrier(event)} 
                        style={{marginBottom:'5px'}}>
                          
                         <option value= 'DEFAULT' disabled >Select Carrier</option>
                         <option value= ''>All</option>
                         {
                           (this.state.FlightCarrierList)?
                            this.state.FlightCarrierList.map((obj, i)=>
                          <option value={obj.id} key={i}>{obj.text}</option>
                         )
                         :
                         <option  value= ''>Select Carrier</option>
}
                        </select>
          </div>
          
        :
        <div></div>
            }
          <div className="col" style={{paddingLeft:'0px', marginLeft:'-5px' }}>
          <input  placeholder="Flight Number" 
                        type="text" 
                        ref = "FlightNo"
                        className="form-control"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handleFlightNo(event)} 
                        defaultValue = {this.state.FlightNo}
                        style={{marginBottom:'5px'}}
                        />
          </div>
          <div className="col customDatePickerWidth">
          <DatePicker
            placeholderText="Flight date"
            className="form-control"
            ref="FlightDate"
            selected={this.state.FlightDate}
            width='100%'
            onChange={date => this.setFlightDate(date)}
            selectsEnd
            dateFormat="dd/MM/yyyy"
          />
          
          </div>
          <div className="col" style={{marginTop:'5px'}}>
          <input  onClick={this.fetchFlightDetails} 
                  type="button" 
                  className="btn btn-info SearchTblbuttonpos" 
                  name="" 
                  value="Search" 
                  style={{marginLeft:'10px'}} />
          <input  onClick={this.ClearInputs} 
                  type="button" 
                  className="btn btn-warning SearchTblbuttonpos" 
                  name="" 
                  value="Clear" 
                  style={{marginLeft:'10px'}} />
                  <Link to="/home">
          <input type="button" 
                  className="btn btn-light SearchTblbuttonpos" 
                  name="" 
                  value="Back" 
                  style={{marginLeft:'10px'}} />
        
                  </Link>
          
        
        </div>
          

          </div>


           
          {                        
                        this.state.isLoading ?
                        <div className='loader' style={{textAlign:'center', color:'#3a99c8',width:'100%'}}><i className='fa fa-spinner fa-spin fa-3x fa-fw'></i></div>
                        :
                        (this.state.FlightData.length > 0)?
                      <div className="row" style={{marginTop:'15px', width:'100%', marginLeft:'-5px'}}>
                        
                        <div className="col"> 
                         <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{marginLeft:'-15px', marginBottom:'15px', width:'100%', borderColor:'blue'}}>
                          <div className="col-5 nav nav-tabs"  role="tablist" style={{borderBottom:'0px'}}>
                            <a onClick={()=>this.handleResultStatus('All')} className="nav-item nav-link active"  data-toggle="tab" href="#nav-CW" role="tab" aria-controls="nav-CW" aria-selected="true">All</a>
                            <a onClick={()=>this.handleResultStatus('Result','Positive')} className="nav-item nav-link" data-toggle="tab" href="#nav-Completed" role="tab" aria-controls="nav-Completed" aria-selected="false">Postive</a>
                            <a onClick={()=>this.handleResultStatus('Result','Negative')} className="nav-item nav-link" data-toggle="tab" href="#nav-Cancelled" role="tab" aria-controls="nav-Cancelled" aria-selected="false">Negative</a>
                            <a onClick={()=>this.handleResultStatus('Status','Pending')} className="nav-item nav-link" data-toggle="tab" href="#nav-Cancelled" role="tab" aria-controls="nav-Cancelled" aria-selected="false">Pending</a>
                          
                          </div>
                          
          <div className="col-2 text-right">  <p className="text-info mb-0">{(this.state.FlightDataSearch.length>0)? this.state.FlightDataSearch.length : <span>No </span> } Records Found.</p> </div>
          <div className="col-1 ">
            <div ref="ddiv" className="row m-0 p-0">
              <div className="col m-0 p-0">
                {<a ref="EXCEL" title="Download Excel" style={{cursor:'pointer'}} onClick={()=>this.handleReport('EXCEL')}> <i className="fa fa-file-excel-o fa-2x text-success"></i></a>} 
              </div>
              <div className="col m-0 p-0">
              {<a ref="PDF" title="Download PDF" style={{cursor:'pointer'}} onClick={()=>this.handleReport('PDF')}> <i className="fa fa-file-pdf-o fa-2x text-danger"></i></a>}
              </div>
              
              <div ref="divLoader" className='col m-0 p-0 loader' style={{textAlign:'center', display:'none', color:'#3a99c8'}}><i className='fa fa-spinner fa-spin fa-2x'></i></div>
            </div>
          </div>
                          <div className="col" style={{ paddingLeft:'0px', width:'100%', marginLeft:'-5px'}}>
                            <input  placeholder="Search" 
                            type="text" 
                            ref="SearchBox"
                            className="form-control"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={(event) => this.handleSearch(event)} 
                            style={{}}
                            />
                          </div>
                          
                        </nav>
                        </div>
                        
          
                      </div>
                      :
                      this.state.NoRecordFound ?
                          <div><br/><p className="text-info pl-2">No Record Found</p></div>
                          :
                        <div></div>
                    }
                    <div id="divCustomeHeight" className="row pr-3 pl-3 pt-0" style={ (this.state.FlightDataSearch.length > 0)?  {overflowY: 'scroll', width:'100%', float: 'left'} : {}}>
                      {
                        (this.state.FlightDataSearch)?                        
                        this.state.FlightDataSearch.map((obj, i)=>
                          <div className="row" key={i} style={{maxHeight:'108px', marginTop:'0px', marginBottom:'15px', marginLeft:'-5px',width:'100%'}}>
                          <div className="card " style={{ color:'black',width:'100%',backgroundColor:'whitesmoke', borderLeftWidth:'5px', borderColor:this.getBorderColor(obj)}}>
                            
                            <div className="card-body" style={{padding:'10px'}}>                             
                            
                              <div className="row">
                              <div className="col-content">
                              <p  style={{ fontSize: '15px', paddingLeft:'35px', marginBottom:'0px', color:'lightgray'}}> #{i + 1 }</p>
                            <span style={{float: 'left', fontSize: '40px', paddingLeft:'25px', paddingRight:'10px', color:this.getBorderColor(obj)}}>
                      
                              <i className="fa fa-flask" aria-hidden="true"></i>
                              </span>
                              </div>
                                <div className="col-3 pl-2 pr-0">
                                  <p style={{marginBottom:'0px',fontWeight:'500'}}>{obj.PassengerName}</p>
                                  <p style={{marginBottom:'10px',fontSize:'11px',fontWeight:'500'}}>{obj.Gender} | {obj.PatientAge} Y</p>
                                  <div className="row">
                                  <div className="col">
                                  <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Booking Ref | PNR </p>
                                  <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.FlightBookingRefNo}</p>
                                  
                                  </div>
                                  <div className="col">
                                  <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}> <span className="pr-3"> Tickect #</span></p>
                                  <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{(obj.TicketNumber)? <span className="pr-3" >{obj.TicketNumber} </span>: <span className="pr-3" >N/A </span> } </p>
                                  
                                  </div>
                                  </div>
                                </div>
                                <div className="col-2 px-0 mr-4" style={{maxWidth:'150px', borderRight:'1px solid lightgray'}}>
                                
                                <p style={{marginBottom:'0px',fontSize:'11px',color:'gray'}}>Passport #</p>
                                <p style={{marginBottom:'10px',fontWeight:'500'}}>{obj.PassengerPassportNo}</p>
                                
                                <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Flight # | Date</p>
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{/*obj.FlightCareer */} {obj.FlightNumber} | {obj.DisplayFlightDate}</p>
                                
                                </div>
                                <div className="col-3 px-2">
                                <p style={{marginBottom:'0px',fontSize:'11px',color:'gray'}}>Test</p>
                                <p style={{marginBottom:'5px',fontSize:'large',fontWeight:'600'}}>{obj.Description}</p>
                                
                                
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.UnitNumber} | {obj.SpecimenId}</p>
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.Location}</p>
                                </div>
                                
                                <div className="col px-2">
                                <p  style={{marginBottom:'0px',fontSize:'11px',color:'gray'}}>Result </p>
                                <p style={{fontSize:'large',fontWeight:'600'}}>{obj._Result}</p>


                                {
                                    (obj.Status == "Completed")?
                                      
                                  (obj.Result == "Negative")?  
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'green'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                :
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'red'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                
                                :
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'darkorange'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Status}</p>
                                }
                                </div>
                                
                                <div className="col px-2">
                                <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Specimen Collection</p>
                                
                                {
                                  (obj.DisplayCollectionDttm)?
                                  <p style={{marginBottom:'15px',fontSize:'small'}}>{obj.DisplayCollectionDttm}</p>
                                :
                                  <p style={{marginBottom:'15px',fontSize:'small'}}>N/A</p>
                                }
                                <p  style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Result Released </p>
                                {
                                  (obj.DisplaySignoutDttm)?
                                <p  style={{marginBottom:'0px',fontSize:'small'}}>{obj.DisplaySignoutDttm}
                                {
                                  (obj.ConsentFormB64 != '')? 
                                    <button title="Consent Form" style={{padding: '0px', marginRight : '10px', border: 'none', float:'right' }} onClick={()=> this.OpenConsentForm(obj.ConsentFormB64)}> <i className="fa fa-picture-o fa-2x text-info"></i> </button>
                                  :
                                  <button hidden> <i className="fa fa-picture-o fa-2x "></i> </button>
                                  
                                }
                                </p>
                                :
                                <p  style={{marginBottom:'0px',fontSize:'small'}}>N/A
                                {
                                  (obj.ConsentFormB64 != '')? 
                                    <button title="Consent Form" style={{padding: '0px', marginRight : '10px', border: 'none', float:'right' }} onClick={()=> this.OpenConsentForm(obj.ConsentFormB64)}> <i className="fa fa-picture-o fa-2x text-info"></i> </button>
                                  :
                                  <button hidden> <i className="fa fa-picture-o fa-2x "></i> </button>
                                  
                                }</p>
                                }
                                
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        )
                        :
                        <div></div>  
                      }
                      </div>
      </div>
		);
	}
}

export default CheckFlight;
