import React from 'react';
import {appCred} from '../AppConfig.js';
import { Link } from 'react-router-dom';


class CheckIndividual extends React.Component {
  constructor(){
    super();
    
    let user = window.mylsataku.get('cache2'); //JSON.parse(window.localStorage.getItem("User"));

    this.state = {
      FlightCarrier : '',
      PatientUnitNumber : '',
      TicketNumber : '',
      Surname : '',
      BookingRef : '',
      access_token : '',
      qPassengerSurName : '',
      qFlightBookingRefNo : '',
      FlightData : [],
      FlightDataSearch : [],
      isLoading : false,
      NoRecordFound : false,
      UserOrgId : user.User.UserOrganizationId,
      UserOrgType : user.User.UserOrganizationType,
      FlightCarrierList : user.User.lstOrganizations
    }
  }

  ClearInputs = () =>{
    this.refs.PatientUnitNumber.value = '';
    this.refs.TicketNumber.value = '';
    this.refs.Surname.value = '';
    this.refs.BookingRef.value = '';
    if(this.state.UserOrgType != "AIRLINE"){
      this.refs.FlightCarrier.value = 'Select Carrier';
    }    

    this.setState({
      FlightData : [],
      FlightDataSearch : [],
      TicketNumber : '',
      PatientUnitNumber : ''
      //FlightNo : '',
      //FlightCarrier: ''
    })
  }

  setSBReqData = () => {
    console.log('setSBReqData');
    let reqData = '';
    if (this.state.UserOrgType == "AIRLINE"){
    reqData = { "key": "PASSENGER", "qFlightCareer" : this.state.UserOrgId, 'qPassengerSurName': this.state.qPassengerSurName , 'qFlightBookingRefNo' : this.state.qFlightBookingRefNo }
    }else{ 
    reqData = { "key": "PASSENGER", "qFlightCareer" : this.state.FlightCarrier, 'qPassengerSurName': this.state.qPassengerSurName , 'qFlightBookingRefNo' : this.state.qFlightBookingRefNo }
    }

    this.fetchFlightDetails(reqData);
  }

  setMRNReqData = () => {
    console.log('setMRNReqData');
    let reqData = { "key": "UNITNUMBER", "qUnitNumber" : this.state.PatientUnitNumber }
    
    this.fetchFlightDetails(reqData);
  }

  setTicketReqData = () => {
    console.log('setTicketReqData');
    let reqData = { "key": "TICKETNUMBER", "qTicketNumber" : this.state.TicketNumber }
    
    this.fetchFlightDetails(reqData);
  }

  fetchFlightDetails = (reqData)=>{
    this.setState({
      isLoading : true
    }) 
    console.log('fetchIndividualFlightDetails called');
    //console.log(this.state.FlightDate);
    
    /*let reqData = '';
    if (this.state.UserOrgType == "AIRLINE"){
    reqData = { "qFlightCareer" : this.state.UserOrgId, 'qPassengerSurName': this.state.qPassengerSurName , 'qFlightBookingRefNo' : this.state.qFlightBookingRefNo }
    }else{ 
    reqData = { "qFlightCareer" : this.state.FlightCarrier, 'qPassengerSurName': this.state.qPassengerSurName , 'qFlightBookingRefNo' : this.state.qFlightBookingRefNo }
    }*/

    window.APIRequestAuth(appCred,'AirlineCovidManager.GetFlightData',reqData).then((apiRes)=>{
      //console.log(reqData);
      //console.log(apiRes);
      let _searchablelist = JSON.parse(JSON.stringify(apiRes.ResponseResult));

      if(apiRes.ResponseCode == 200){
        
        this.setState({
          isLoading : false,
          FlightData : apiRes.ResponseResult,
          FlightDataSearch : _searchablelist
        })
      }
      else{
        this.setState({
          isLoading : false,
          NoRecordFound : true,
          FlightData : apiRes.ResponseResult,
          FlightDataSearch : _searchablelist
        })
      }
    }).catch((error) => {
    console.log(error)
  })

  } 


  handleFlightCarrier = (event) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      FlightCarrier: event.target.value
    });
  }

  handleSurname = (event) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      qPassengerSurName: event.target.value
    });
  }

  handlePatientUnitNumber = (event) =>{
    //console.log('')
    this.setState({
      PatientUnitNumber: event.target.value
    });
  }

  handleTicketNumber = (event) =>{
    //console.log('')
    if (event.target.value.length > event.target.maxLength) {
      event.target.value = event.target.value.slice(0, event.target.maxLength)
      
       }
       
    this.setState({
      TicketNumber: event.target.value
    });
  }

  handleBookingRef = (event) =>{
    //console.log(moment(date).format('DD/MM/YYYY'))
    this.setState({
      qFlightBookingRefNo: event.target.value
    });
  }
  
  getBorderColor = (obj)=>{
    if(obj.Status == "Completed"){
      if(obj.Result == "Positive"){      
        return "red";
      } else if(obj.Result == "Negative"){
        return "green";
      } 
    }else{
      return "darkorange";
    }
  }
	
	componentDidMount () {
    window.CloseNav();
    //L5W4NW
  }
	
  render() {
    
    return (
      <div className="row m-4">
        <br/><br/>
        
        <span style={{width:'100%', fontSize:'25px', fontWeight:'500', paddingBottom:'10px'}}> Check Individual Passenger </span>
        
        <div className="row" style={{marginTop:'0px', width:'100%', marginLeft:'-5px'}}>
                        
                        <div className="col p-0"> 
                         <nav className="navbar navbar-expand-lg px-2" style={{ marginBottom:'15px', width:'100%'}}>
                          <div className="col nav nav-pills"  role="tablist" style={{borderBottom:'0px'}}>
                            <a onClick={this.ClearInputs} style={{border:'1px solid', marginRight:'5px'}} className="nav-item nav-link active" data-toggle="tab" href="#nav-SURNAME" role="tab" aria-controls="nav-Completed" aria-selected="false">Flight Information</a>
                            <a onClick={this.ClearInputs} style={{border:'1px solid', marginRight:'5px'}} className="nav-item nav-link"  data-toggle="tab" href="#nav-MRN" role="tab" aria-controls="nav-CW" aria-selected="true">AKUH Numbers</a>
                            <a onClick={this.ClearInputs} style={{border:'1px solid'}} className="nav-item nav-link"  data-toggle="tab" href="#nav-Tno" role="tab" aria-controls="nav-CW" aria-selected="true">Ticket Number</a>
                            
                          </div>                          
                        </nav>
<div className="tab-content">
<div className = "tab-pane fade show active" id="nav-SURNAME">
  <div className="row " style={{width:'100%', marginLeft:'12px'}}>
        


        { 
              (this.state.UserOrgType != "AIRLINE")?
              <div className="col" style={{paddingLeft:'0px', paddingRight:'40px',marginLeft:'-5px'}}>
            <select placeholder="Flight Carrier" 
                        className="form-control"
                        ref="FlightCarrier"
                        required
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handleFlightCarrier(event)} 
                        style={{marginBottom:'5px'}}>
                        <option>Select Carrier</option>
                         {
                           (this.state.FlightCarrierList)?
                            this.state.FlightCarrierList.map((obj, i)=>
                          <option value={obj.id} key={i}>{obj.text}</option>
                         )
                         :
                         <option>Select Carrier</option>
}
                        </select>
          </div>
          
        :
        <div></div>
            }

        <div className="col" style={{paddingLeft:'0px',paddingRight:'20px', marginLeft:'-5px'}}>
          <input  placeholder="Surname" 
                        type="text" 
                        ref="Surname"
                        required
                        className="form-control"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handleSurname(event)} 
                        style={{marginBottom:'5px'}}
                        />
          </div>

          <div className="col" style={{}}>
          <input  placeholder="Booking Reference #" 
                        type="text" 
                        ref="BookingRef"
                        required
                        className="form-control"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handleBookingRef(event)} 
                        style={{marginBottom:'5px'}}
                        />
          </div>
                  
          <div className="col" style={{marginTop:'5px'}}>
          <input  onClick={this.setSBReqData} 
                  type="button" 
                  className="btn btn-info SearchTblbuttonpos" 
                  name="" 
                  value="Search" 
                  style={{marginLeft:'10px'}} />
          <input  onClick={this.ClearInputs} 
                  type="button" 
                  className="btn btn-warning SearchTblbuttonpos" 
                  name="" 
                  value="Clear" 
                  style={{marginLeft:'10px'}} />
                  <Link to="/home">
                  <input  //onClick={this.ClearInputs}                   
                  type="button" 
                  className="btn btn-light SearchTblbuttonpos" 
                  name="" 
                  value="Back" 
                  style={{marginLeft:'10px'}} />
        
                  </Link>
          
        </div>
          
        </div>
        
        </div>
  
  <div className = "tab-pane fade " id="nav-MRN">
  <div className="row" style={{width:'100%', marginLeft:'3px'}}>        

          <div className="col p-0 ml-1" style={{}}>
          <input  placeholder="Medical Record / L Number" 
                        type="text" 
                        ref="PatientUnitNumber"
                        required
                        className="form-control"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handlePatientUnitNumber(event)} 
                        style={{marginBottom:'5px'}}
                        />
          </div>
                  
          <div className="col" style={{marginTop:'5px'}}>
          <input  onClick={this.setMRNReqData} 
                  type="button" 
                  className="btn btn-info SearchTblbuttonpos" 
                  name="" 
                  value="Search" 
                  style={{marginLeft:'10px'}} />
          <input  onClick={this.ClearInputs} 
                  type="button" 
                  className="btn btn-warning SearchTblbuttonpos" 
                  name="" 
                  value="Clear" 
                  style={{marginLeft:'10px'}} />
                  <Link to="/home">
                  <input  //onClick={this.ClearInputs}                   
                  type="button" 
                  className="btn btn-light SearchTblbuttonpos" 
                  name="" 
                  value="Back" 
                  style={{marginLeft:'10px'}} />
        
                  </Link>
          
        </div>
          <div className="col"></div>
        </div>
        
        </div>

   <div className = "tab-pane fade " id="nav-Tno">
  <div className="row" style={{width:'100%', marginLeft:'3px'}}>        

          <div className="col p-0 ml-1" style={{}}>
          <input  placeholder="Ticket Number" 
                        type="number"
                        maxLength="13" 
                        ref="TicketNumber"
                        required
                        className="form-control"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(event) => this.handleTicketNumber(event)} 
                        style={{marginBottom:'5px'}}
                        />
          </div>
                  
          <div className="col" style={{marginTop:'5px'}}>
          <input  onClick={this.setTicketReqData} 
                  type="button" 
                  className="btn btn-info SearchTblbuttonpos" 
                  name="" 
                  value="Search" 
                  style={{marginLeft:'10px'}} />
          <input  onClick={this.ClearInputs} 
                  type="button" 
                  className="btn btn-warning SearchTblbuttonpos" 
                  name="" 
                  value="Clear" 
                  style={{marginLeft:'10px'}} />
                  <Link to="/home">
                  <input  //onClick={this.ClearInputs}                   
                  type="button" 
                  className="btn btn-light SearchTblbuttonpos" 
                  name="" 
                  value="Back" 
                  style={{marginLeft:'10px'}} />
        
                  </Link>
          
        </div>
          <div className="col"></div>
        </div>
        
        </div>
       
        </div>
                        </div>
                        
          
                      </div>
        
        
{                        
                        this.state.isLoading ?
                        <div className='loader' style={{textAlign:'center', color:'#3a99c8',width:'100%'}}><i className='fa fa-spinner fa-spin fa-3x fa-fw'></i></div>
                        :
                        (this.state.FlightDataSearch)?                        
                        this.state.FlightDataSearch.map((obj, i)=>
                          <div className="row" key={i} style={{maxHeight:'108px', marginTop:'50px', marginBottom:'15px', marginLeft:'0px',width:'100%'}}>
                          <div className="card " style={{ color:'black',width:'1250px',backgroundColor:'whitesmoke', borderLeftWidth:'5px', borderColor:this.getBorderColor(obj)}}>
                            
                            <div className="card-body" style={{padding:'10px'}}>                             
                            
                              <div className="row">
                              <div className="col-content">
                              <br/><br/>
                            <span style={{float: 'left', fontSize: '60px', paddingLeft:'25px', paddingRight:'10px', color:this.getBorderColor(obj)}}>
                      
                              <i className="fa fa-flask" aria-hidden="true"></i>
                              </span>
                              </div>
                                <div className="col-5 pl-2 pr-4 mr-4" style={{ borderRight:'1px solid lightgray'}}>
                                  <p style={{marginBottom:'0px',fontWeight:'500',fontSize:'x-large'}}>{obj.PassengerName}</p>
                                  <span style={{marginBottom:'10px',fontSize:'large',fontWeight:'500'}}>{obj.Gender} | {obj.PatientAge} Y</span>
                                  <br/><br/>
                                  
                                  
                                  <span style={{marginBottom:'0px',fontSize:'15px', color:'gray'}}>Carrier</span>
                                  <span style={{marginBottom:'0px',fontSize:'15px', color:'gray', float:'right'}}>Passport #</span><br/>
                                  <span style={{marginBottom:'0px',fontSize:'large'}}>{obj.FlightCareer}</span>
                                  <span style={{marginBottom:'0px',fontSize:'large',fontWeight:'500', float:'right'}}>{obj.PassengerPassportNo}</span><br/><br/>
                                  
                                  
                                  <span style={{marginBottom:'0px',fontSize:'15px', color:'gray'}}> PNR | Ticket #</span>
                                  {/* <span style={{marginBottom:'0px', paddingLeft : '50px',fontSize:'15px', color:'gray'}}>Ticket #</span> */}
                                <span style={{marginBottom:'0px',fontSize:'15px', color:'gray', float:'right'}}>Flight # | Date</span><br/>

                                  {/* <span style={{marginBottom:'0px',fontSize:'large',fontWeight:''}}>{obj.FlightBookingRefNo}</span> */}
                                  {(obj.TicketNumber)? <span style={{marginBottom:'0px',fontSize:'large',fontWeight:''}} >{obj.FlightBookingRefNo} &nbsp; | &nbsp; {obj.TicketNumber} </span>: <span style={{marginBottom:'0px',fontSize:'large',fontWeight:''}} > {obj.FlightBookingRefNo} &nbsp; | &nbsp; N/A </span> } 
                                <span style={{marginBottom:'0px',fontSize:'large',fontWeight:'', float:'right'}}>{/*obj.FlightCareer */} {obj.FlightNumber} | {obj.DisplayFlightDate}</span>
                                
                                </div>
                                <div className="col-4  pr-2 pl-0">
                                <p style={{marginBottom:'0px',fontSize:'15px',color:'gray'}}>Test</p>
                                <p style={{marginBottom:'25px',fontSize:'x-large',fontWeight:'600'}}>{obj.Description}</p>
                                
                                <p style={{marginBottom:'0px',fontSize:'large',fontWeight:''}}>{obj.UnitNumber} | {obj.SpecimenId}</p>
                                <p style={{marginBottom:'0px',fontSize:'large',fontWeight:''}}>{obj.Location}</p>
                                <br/>
                                <span style={{marginBottom:'0px',fontSize:'15px', color:'gray'}}>Specimen Collection</span>
                                <span  style={{marginBottom:'0px',marginRight:'30px',fontSize:'15px', color:'gray', float:'right'}}>Result Released </span><br/>
                                
                                {
                                  (obj.DisplayCollectionDttm)?
                                  <span style={{marginBottom:'15px',fontSize:'large'}}>{obj.DisplayCollectionDttm}</span>
                                :
                                  <span style={{marginBottom:'15px',fontSize:'large'}}>N/A</span>
                                }
                                {
                                  (obj.DisplaySignoutDttm)?
                                <span  style={{marginBottom:'0px',marginRight:'30px',fontSize:'large', float:'right'}}>{obj.DisplaySignoutDttm}</span>
                                :
                                <span  style={{marginBottom:'0px',marginRight:'30px',fontSize:'large', float:'right'}}>N/A</span>
                                }
                                
                                </div>
                                
                                <div className="col px-4">
                                <p  style={{marginBottom:'0px',fontSize:'15px',color:'gray'}}>Result </p>
                                <br/>
                                <p style={{fontSize:'x-large',fontWeight:'600'}}>{obj._Result}</p>

                                <br/>
                                {
                                    (obj.Status == "Completed")?
                                      
                                  (obj.Result == "Negative")?  
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'green', fontSize:'x-large'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                :
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'red', fontSize:'x-large'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                
                                :
                                <p style={{marginBottom:'0px',fontWeight:'600', color:'darkorange', fontSize:'x-large'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Status}</p>
                                }
                                </div>
                                
                                
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        )

                        
                        :
                        
                          this.state.NoRecordFound ?
                          <div><br/><p className="text-info pl-2">No Record Found</p></div>
                        :
                        <div></div>  
                      }

                      {  /*                      
                        this.state.isLoading ?
                        <div className='loader' style={{textAlign:'center', color:'#3a99c8',width:'100%'}}><i className='fa fa-spinner fa-spin fa-3x fa-fw'></i></div>
                        :
                        (this.state.FlightDataSearch)?
                        this.state.FlightDataSearch.map((obj, i)=>
                          <div className="row" key={i} style={{marginTop:'50px', marginLeft:'-5px',width:'100%'}}>
                          <div className="card " style={{ transform:'scale(1.35)',marginLeft:'375px', marginTop:'10px', color:'black',width:'550px',backgroundColor:'whitesmoke', borderLeftWidth:'5px', borderColor:this.getBorderColor(obj)}}>
                            <div className="card-header" style={{paddingTop:'5px', paddingBottom:'5px'}}>
                              <div className="row">
                                <div className="col">
                      <p style={{marginBottom:'0px',fontWeight:'500'}}>{obj.PassengerName}</p>
                                <p style={{marginBottom:'10px',fontSize:'11px',fontWeight:'500'}}>{obj.Gender} | {obj.PatientAge} Y</p>
                                
                                
                                <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Booking Reference / PNR</p>
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.FlightBookingRefNo}</p>
                                </div>
                                <div className="col">
                                <p className="text-right"style={{marginBottom:'25px',fontWeight:'500'}}>{obj.PassengerPassportNo}</p>
                                <p className="text-right" style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Flight # / Date</p>
                      <p className="text-right" style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.FlightCareer} {obj.FlightNumber} / {obj.DisplayFlightDate}</p>
                                
                                </div>
                              </div>
                              
                              
  
                            </div>
                            <div className="card-body" style={{padding:'10px'}}>
                            <span style={{float: 'left', fontSize: '50px', padding:'5px',color:'orange'}}><i className="fa fa-flask" aria-hidden="true"></i></span>
                            <div className="row">
                                <div className="col">
                                <p style={{marginBottom:'0px',fontSize:'11px',color:'gray'}}>Test</p>
                                <p style={{marginBottom:'0px',fontSize:'large',fontWeight:'600'}}>{obj.Description}</p>
                                
                                
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.UnitNumber} / {obj.SpecimenNumber}</p>
                                <p style={{marginBottom:'0px',fontSize:'small',fontWeight:''}}>{obj.Location}</p>
                                </div>
                                <div className="col">
                                <p className="text-right" style={{marginBottom:'0px',fontSize:'11px',color:'gray'}}>Result </p>
                                <p className="text-right" style={{fontSize:'large',fontWeight:'600'}}>{obj._Result}</p>
                                {
                                  (obj.Result == "Negative")?  
                                <p className="text-right" style={{marginBottom:'0px',fontWeight:'600', color:'green'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                :
                                <p className="text-right" style={{marginBottom:'0px',fontWeight:'600', color:'red'}}><span style={{paddingRight:'5px'}}><i className="fa fa-square"></i>  </span>{obj.Result}</p>
                                
                                }
                                </div>
                                </div>
                              </div>
                            <div className="card-footer">
                            <div className="row">
                                <div className="col">
                                <p style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Specimen Collection</p>
                                <p style={{marginBottom:'0px',fontSize:'small'}}>{obj.DisplayCollectionDttm}</p>
                                </div>
                                <div className="col">
                                <p className="text-right" style={{marginBottom:'0px',fontSize:'11px', color:'gray'}}>Result Released </p>
                                <p className="text-right" style={{marginBottom:'0px',fontSize:'small'}}>{obj.DisplaySignoutDttm}</p>
                                
                                </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        )
                        
                        :
                        
                          this.state.NoRecordFound ?
                          <div><br/><p>No Record Found</p></div>
                          :
                          <div> </div>
                              */}

          </div>
		);
	}
}

export default CheckIndividual;
