import React from 'react';
//import PDFViewer from 'pdf-viewer-reactjs'

class PDFView extends React.Component {
	render() {
		return (
			<div className="row m-4">
			</div>
		);
	}
}

export default PDFView;
