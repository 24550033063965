import React from 'react';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';

class ModalContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      ModalContext : {
        Header : 'Test',
        Content : ()=>{ return (
          <div className="">
              {"Hello world"}
          </div>
          ) 
        },
        Footer : ()=>{return (
          
            <div style={{marginRight:'20px'}}>
              <span style={{float:'right' ,padding:'10px', paddingLeft:'15px'}}>
                <button onClick={this.MdlClose} type="button" className="btn bg-pink btn-sm" style={{marginTop:'3px' , marginLeft:'5px'}}><i className="fa fa-times" aria-hidden="true" style={{paddingRight:'10px'}}></i>Close</button>
              </span>
            </div>
          
          )
        }
      }
    }
  }

  SetModalContext = (Header,funcModalContent,funcModalFooter) =>{
    
      this.setState({
        ModalContext : {
          Header : Header,
          Content : funcModalContent,
          Footer : funcModalFooter
        }
      });
    
  }

  MdlClose = () =>{
    window.CloseModal();
  }

	componentDidMount () {
    //console.log(this.props);
    window._ModalContext = this;
	}
	
  render() {
    return (
		
      <div id="mdl" className="customModal" style={{display: 'none'}}>
        <div className="jumbotron customModalContent" style={{opacity: 0, borderRadius: '1.3em !important', margin: 'auto', maxWidth: '600px', marginTop: '4%', padding: '25px', overflowY: 'hidden', maxHeight: '540px'}}>
          <ModalHeader Header={this.state.ModalContext.Header}/>
          <ModalContent Content={this.state.ModalContext.Content}/>
          <ModalFooter Footer={this.state.ModalContext.Footer}/>
        </div>
      </div>

		);
	}
}

export default ModalContainer;