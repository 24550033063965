import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import { BrowserRouter as Router, Switch, Route, Link , Redirect} from 'react-router-dom';
import Sidebar from './Sidebar';
import CheckIndividual from './CheckIndividual';
import CheckFlight from './CheckFlight';
import CheckAirline from './CheckAirline';
import {appCred} from '../AppConfig.js';
import ChangePassword from './ChangePassword';
import PDFView from './PDFView';
import ModalContainer from './ModalContainer';

class Main extends React.Component {
	constructor(){
    super();
    
    let user = window.mylsataku.get('cache2'); //JSON.parse(window.localStorage.getItem("User"));

		this.state = {
			isUserLoggedIn : window.mylsataku.get('cache1'),//localStorage.getItem('isUserLoggedIn'),
      User : window.mylsataku.get('cache2'), //JSON.parse(localStorage.getItem('User')),      
      srcOrgLogoUrl : user.User.OrgLogoUrl
      //UserAssignedMenus : JSON.parse(localStorage.getItem('UserAssignedMenus'))
		};
  }
  
  componentDidMount(){
    //console.log(this.state.User)
    
  }

  render() {

    

    let user = window.mylsataku.get('cache2');//JSON.parse(window.localStorage.getItem("User"));
    let isForceChangePassword = (user.User.ForceChangePassword == "Y")? true : false;
    let userRole = user.User.UserOrganizationType;

    //console.log(user.User.ForceChangePassword);
    //console.log('HERE AMQ ' + isForceChangePassword);
    const NormalRoutes = () => (
      <Switch>
                    <Route exact path='/' component={()=><Home User={this.state.User}/>} />
                    <Route exact path='/home' component={()=><Home User={this.state.User} />} />
                    <Route exact path='/CheckIndividual' component={()=> <CheckIndividual SetModalContent = {this.SetModalContent} />} />
                    <Route exact path='/CheckFlight' component={()=> <CheckFlight SetModalContent = {this.SetModalContent} />} />
                    <Route exact path='/ChangePassword' component={ChangePassword} />
                    {
                      (userRole == "ADMIN")? <Route exact path='/CheckAirline' component={()=> <CheckAirline SetModalContent = {this.SetModalContent} />} /> : 
                      <Route render={() => <Redirect to="/" />} />
                    }
                    
                    <Route exact path='/PDFView' component={PDFView} />
                  
                  </Switch>
    );
    
    const ForceAuthRoutes = () => (
      <Switch>
        <Route exact path='/' component={ChangePassword} />
        <Route exact path='/ChangePassword' component={ChangePassword} />
        <Route render={() => <Redirect to="/ChangePassword" />} />
      </Switch>
    )
    return (
      <Router>
        <Helmet>
            <title>Travel Check AKU </title>
            <link href="/Theme/Custom/animationStyles.css" rel="stylesheet"/>
            <link href="/Theme/Custom/Custom.css" rel="stylesheet"/>
        </Helmet>
        <div>
          <div style={{padding: 0}}>
          <Header />
          <Sidebar User={this.state.User}/>
          <div style={{paddingTop: '50px',height:'100vh', backgroundImage: 'linear-gradient(0deg, lightblue, white)' /*backgroundImage:'url(/airplane.png)', 'backgroundRepeat': 'no-repeat', backgroundSize: 'cover',
			'backgroundPosition': 'center'*/}}>
            <div className="">
              <div className="">
              <img src={'/akuh.png'} style={{float: 'left',paddingLeft:'15px', paddingTop:'15px', display:'block',maxHeight:'65px'}} ></img>
              <img src={this.state.srcOrgLogoUrl} style={{float: 'right', paddingRight:'15px', paddingTop:'10px',maxHeight:'65px'}} ></img>
              </div>
              <div>
                <br/><br/><br/>
                {
                  (isForceChangePassword)? <ForceAuthRoutes /> : <NormalRoutes />
                }
              </div>
            </div>
          </div>
        </div>
        <Footer ServerName={appCred.appServer} />
        <ModalContainer ModalContext={this.state.ModalContext}/>
        </div>
      </Router>
		);
	}
}

export default Main;
