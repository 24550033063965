import React from 'react';

class ModalHeader extends React.Component{

  MdlClose = () =>{
    window.CloseModal();
  }

  render(){
    return (
      <div className="customModalHeader">
                <div style={{padding:'15px', color : 'white'}}>
                    <span>{this.props.Header}</span>
                    <span style={{float: 'right', cursor: 'pointer', fontWeight: 'bolder'}} onClick={this.MdlClose}><i className="fa fa-times"></i></span>
                </div>
            </div>
    )
  }
}

export default ModalHeader;
